<template>
  <validation-observer v-slot="{ handleSubmit }" class="create-new-password" tag="div">
    <v-form @submit.prevent="handleSubmit(onSubmit)">
      <template v-if="isNewUser" #header>
        <span class="create-new-password__title">Добро пожаловать. Придумайте пароль</span>
      </template>
      <template #default>
        <v-form-row>
          <v-form-field
            label="ПАРОЛЬ"
            rules="required|min:8|confirmed:confirmPassword"
            :error-message="errors.newPassword"
          >
            <template #default="{ validationErrors }">
              <v-input v-model="newPassword" :is-error="!!validationErrors.length" type="password" />
            </template>
          </v-form-field>
        </v-form-row>
        <v-form-row>
          <v-form-field label="ПОВТОРИТЕ ПАРОЛЬ" rules="required" vid="confirmPassword">
            <template #default="{ validationErrors }">
              <v-input v-model="confirmPassword" :is-error="!!validationErrors.length" type="password" />
            </template>
          </v-form-field>
        </v-form-row>
      </template>
      <template #footer>
        <v-button primary type="submit">{{ isNewUser ? 'Создать аккаунт' : 'Сменить пароль' }}</v-button>

        <span v-if="isNewUser" class="create-new-password__footer-text">
          Нажимая «Создать аккаунт», вы принимаете усовия
          <v-button class="create-new-password__offer-link" is-text href="#" target="_blank">
            Политики обработки персональных данных </v-button
          >.</span
        >
      </template>
    </v-form>
  </validation-observer>
</template>

<script>
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VButton from '@/components/common/VButton.vue'
import { authService } from '@/services/http'
import { getFirstErrorForFields } from '@/utils/common'
import { loadingService } from '@/services/loading'

export default {
  name: 'CreateNewPassword',
  components: { VFormRow, VForm, VFormField, VInput, VButton },
  props: {
    isNewUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      errors: {},
      loading: false
    }
  },
  watch: {
    loading(val) {
      loadingService.setGlobalLoading(val)
    }
  },
  methods: {
    onSubmit() {
      const { uid, token } = this.$route.params
      this.errors = {}
      this.loading = true
      authService
        .confirmPassword({ uid, token, newPassword: this.newPassword })
        .then(() => {
          this.loading = false
          return this.$router.push({ name: 'login-staff' })
        })
        .catch(error => {
          const flatErrors = { ...error.details, ...error.details.ownerProfile }
          this.errors = getFirstErrorForFields(flatErrors)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.create-new-password {
  &__title {
    margin-bottom: 24px;
    font-weight: $--font-weight-bold;
    font-size: $--font-size-title;
    line-height: 33px;
  }
  &__offer-link {
    color: inherit;
    text-decoration: underline;
  }
  &__footer-text {
    margin-top: 16px;
    opacity: 0.4;
  }
}
</style>
